<template>
  <div class="company">
    <div class="company-body">
      <div class="company-body-content">
        <div class="company-account">
          <div class="company-account-l">
            <p class="company-account-title">
              基本信息
              <button v-if="0" @click="defaultfRegsterDjhm()">设置</button>
            </p>

            <table class="accountTable">
              <tbody>
                <tr>
                  <td>企业名称</td>
                  <td class="enter-td">
                    <input
                      v-model="EnterpriseInfoFor.company"
                      class="td-ipt"
                      placeholder="请输入"
                      type="text"
                      @input="companyChange"
                    />
                  </td>
                </tr>
                <tr>
                  <td>统一社会信用代码</td>
                  <td class="enter-td">
                    <input
                      class="td-ipt"
                      v-model="EnterpriseInfoFor.creditCode"
                      placeholder="请输入"
                      type="text"
                    />
                  </td>
                </tr>
                <tr v-if="0">
                  <td>申报个税密码</td>
                  <td class="enter-td">
                    <input
                      class="td-ipt"
                      v-model="shenbaoPassword"
                      placeholder="请输入"
                      type="text"
                    />
                    <span
                      style="
                        text-align: right !important;
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        color: #4c8afc;
                        cursor: pointer;
                      "
                      @click="checkShenBaoPassword"
                    >
                      校验
                    </span>
                  </td>
                </tr>
                <tr v-if="0">
                  <td>人员报送</td>
                  <td class="enter-td">
                    <span
                      style="
                        text-align: right !important;
                        position: absolute;
                        right: 20px;
                        top: 20px;
                        color: #4c8afc;
                        cursor: pointer;
                      "
                    >
                      导入 报送
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <p
              style="text-align: right; padding-bottom: 20px; color: #bbb"
              v-if="detailList.length > 1"
            >
              此操作修改默认绑定的登记企业
              <span
                style="
                  color: #4c8afc;
                  display: inline-block;
                  padding-left: 10px;
                  cursor: pointer;
                "
                @click="morenShow = true"
              >
                修改</span
              >
            </p>
            <table class="accountTable">
              <tbody>
                <tr>
                  <td>公司地址</td>
                  <td class="enter-td">
                    <el-cascader
                      size="large"
                      :options="options"
                      v-model="updataCity"
                      @change="addressChange"
                    ></el-cascader>
                  </td>
                </tr>
                <tr>
                  <td>详细地址</td>
                  <td class="enter-td">
                    <el-input
                      type="textarea"
                      :autosize="{ minRows: 1 }"
                      placeholder="请输入"
                      v-model="EnterpriseInfoFor.enterpriseAddress"
                      class="linIpt"
                    >
                    </el-input>
                  </td>
                </tr>
              </tbody>
            </table>
            <table class="accountTable">
              <tbody>
                <tr>
                  <td>纳税人性质</td>
                  <td class="enter-td">
                    <el-select
                      v-model="EnterpriseInfoFor.companyNature"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in EnterpriseType"
                        :key="item.key"
                        :label="item.value"
                        :value="item.key"
                      >
                      </el-option>
                    </el-select>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="company-account-l">
            <p class="company-account-title">银行账户信息</p>
            <table
              class="accountTable"
              v-for="(item, index) in EnterpriseInfoFor.bankList"
              :key="index"
            >
              <tbody>
                <tr>
                  <td>开户行名称</td>
                  <td class="enter-td">
                    <slot v-if="item.bankcardId">
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 1 }"
                        placeholder="请输入"
                        v-model="item.bankName"
                        readonly
                        class="linIpt"
                        :class="{ disabledClass: item.bankcardId }"
                      >
                      </el-input>
                    </slot>
                    <slot v-else>
                      <el-input
                        type="textarea"
                        :autosize="{ minRows: 1 }"
                        placeholder="请输入"
                        v-model="item.bankName"
                        class="linIpt"
                        :class="{ disabledClass: item.bankcardId }"
                      >
                      </el-input>
                    </slot>
                    <span
                      class="el-icon-remove-outline inner-del-icon"
                      @click="bankDel(item, index)"
                    ></span>
                  </td>
                </tr>
                <tr>
                  <td>账号</td>
                  <td class="enter-td">
                    <slot v-if="item.bankcardId">
                      <input
                        v-model="item.bankCardNum"
                        placeholder="请输入"
                        value=""
                        readonly
                        type="text"
                        @input="
                          item.bankCardNum = item.bankCardNum
                            .replace(/\D+/g, '')
                            .replace(/(\d{4})/g, '$1 ')
                            .replace(/ $/, '')
                        "
                      />
                    </slot>
                    <slot v-else>
                      <input
                        v-model="item.bankCardNum"
                        placeholder="请输入"
                        value=""
                        type="text"
                        @input="
                          item.bankCardNum = item.bankCardNum
                            .replace(/\D+/g, '')
                            .replace(/(\d{4})/g, '$1 ')
                            .replace(/ $/, '')
                        "
                      />
                    </slot>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="bank-add-box">
              <span
                class="el-icon-circle-plus-outline inner-add-icon"
                @click="bankAdd"
              ></span>
            </div>
          </div>
        </div>
        <div class="clear"></div>
        <div class="company-account">
          <div class="company-account-l" v-if="0">
            <p class="company-account-title">申报个税信息</p>
            <table class="accountTable">
              <tbody>
                <tr>
                  <td>申报个税密码</td>
                  <td>
                    <div
                      style="text-align: right; cursor: pointer"
                      @click="passwordShow = true"
                    >
                      设置
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="company-account-l" v-if="false">
            <p class="company-account-title">申报国税信息</p>
            <table class="accountTable">
              <tbody>
                <tr>
                  <td>申报国税密码</td>
                  <td>
                    <input
                      v-if="!psw2"
                      v-model="value2"
                      class="td-ipt"
                      placeholder="选填"
                      type="password"
                    />
                    <input
                      v-if="psw2"
                      v-model="value2"
                      class="td-ipt"
                      placeholder="选填"
                      type="text"
                    />
                    <div class="td-icon">
                      <svg-icon
                        v-if="!psw2"
                        icon-class="icon-vivw1"
                        class="icon-vivw"
                        @click="psw2 = true"
                      ></svg-icon>
                      <svg-icon
                        v-if="psw2"
                        icon-class="icon-vivw2"
                        class="icon-vivw"
                        @click="psw2 = false"
                      ></svg-icon>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <el-button class="plainStyle" @click="perfectEnterpriseInfo" plain>
            保 存
          </el-button>
        </div>
      </div>
    </div>
    <TheFooter></TheFooter>
    <div class="popup-layer" v-if="passwordShow">
      <div class="popup-layer-bg" @click="passwordShow = false"></div>
      <div class="password-main">
        <div class="popup-layer-close" @click="passwordShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="addStaffPopup-header">个税密码设置</div>
        <div class="password-content">
          <div class="password-content-box">
            <div class="password-box-l">企业名称</div>
            <div class="password-box-r">
              <div class="paddword-text">北京石景山测试有限公司</div>
            </div>
          </div>
          <div class="password-content-box">
            <div class="password-box-l">申报密码</div>
            <div class="password-box-r">
              <input type="text" placeholder="请输入" />
            </div>
          </div>
          <div class="password-content-box" v-if="0">
            <div class="password-box-l">地区选择</div>
            <div class="password-box-r">
              <el-select v-model="geshui" placeholder="请选择">
                <el-option
                  v-for="item in geshuiList"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                >
                </el-option>
              </el-select>
            </div>
          </div>
          <div class="earmarked-footer">
            <div class="earmarked-btn">保 存</div>
          </div>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="baosongShow">
      <div class="popup-layer-bg" @click="baosongShow = false"></div>
      <div class="baosong-main">
        <div class="popup-layer-close" @click="baosongShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="baosong-heigth">
          <div></div>
          <table class="accountTable baosongTable">
            <thead>
              <tr>
                <th><div class="bs-width1">姓名</div></th>
                <th><div class="bs-width2">证件类型</div></th>
                <th><div class="bs-width3">性别</div></th>
                <th><div class="bs-width4">人员状态</div></th>
                <th><div class="bs-width5">报送状态</div></th>
                <th><div class="bs-width6">身份验证状态</div></th>
                <th><div class="bs-width7">手机号码</div></th>
                <th><div class="bs-width8">任职受雇类型</div></th>
                <th><div class="bs-width9">国籍</div></th>
              </tr>
            </thead>
          </table>
          <div class="table-overflow">
            <table class="accountTable baosongTable">
              <tbody>
                <tr v-for="key in 10" :key="key">
                  <td><div class="bs-width1">奈良鹿丸</div></td>
                  <td><div class="bs-width2">居民身份证</div></td>
                  <td><div class="bs-width3">女</div></td>
                  <td><div class="bs-width4">正常</div></td>
                  <td><div class="bs-width5">报送成功</div></td>
                  <td><div class="bs-width6">验证成功</div></td>
                  <td><div class="bs-width7">18811234567</div></td>
                  <td><div class="bs-width8">雇员</div></td>
                  <td><div class="bs-width9">中国</div></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="baosong-footer">
          <div class="earmarked-btn" @click="baosongShow = false">保 存</div>
        </div>
      </div>
    </div>
    <div class="popup-layer" v-if="morenShow">
      <div class="popup-layer-bg" @click="morenShow = false"></div>
      <div class="baosong-main">
        <div class="popup-layer-close" @click="morenShow = false">
          <svg-icon icon-class="popup-close"></svg-icon>
        </div>
        <div class="baosong-heigth">
          <div></div>
          <table class="accountTable baosongTable">
            <thead>
              <tr>
                <th><div class="bs-width1">序号</div></th>
                <th><div class="moren-width2">企业名称</div></th>
                <th>
                  <div class="bs-width3">操作</div>
                </th>
              </tr>
            </thead>
          </table>
          <div class="table-overflow">
            <table class="accountTable baosongTable">
              <tbody>
                <tr v-for="key in 10" :key="key">
                  <td>
                    <div class="bs-width1">{{ key }}</div>
                  </td>
                  <td>
                    <div class="moren-width2">
                      杨思坤科技公司0 ----- {{ key }}
                      <span class="moren-icon" v-if="key === 1">默认</span>
                    </div>
                  </td>
                  <td>
                    <div
                      class="bs-width3"
                      style="color: #4c8afc; cursor: pointer"
                    >
                      设置
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="baosong-footer">
          <div class="earmarked-btn" @click="morenShow = false">保 存</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  deleteEnterpriseBank,
  queryEnterpriseInfoForUser,
  perfectEnterpriseInfo,
  queryRegsterDetail,
  enterpriseRegister,
  checkShenBaoPassword,
  defaultfRegsterDjhm,
} from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      EnterpriseInfoFor: {},
      updataCity: [],
      options: this.$cityData,
      passwordShow: false,
      baosongShow: false,
      morenShow: false,
      psw1: false,
      psw2: false,
      value1: "",
      value2: "",
      geshui: "",
      shenbaoPassword: "",
      djxhid: "",
      EnterpriseType: [
        {
          key: 1,
          value: "小规模公司",
        },
        {
          key: 2,
          value: "一般纳税人公司",
        },
      ],
      geshuiList: [
        {
          key: 1,
          value: "北京",
        },
        {
          key: 2,
          value: "天津",
        },
      ],
      detailList: [],
    };
  },
  components: {
    TheFooter,
  },
  mounted() {
    this.queryEnterpriseInfoForUser();
    // this.queryRegsterDetail();
    // this.enterpriseRegister();
  },
  methods: {
    shezhiFun() {
      this.enterpriseRegister();
    },
    defaultfRegsterDjhm() {
      let param = {
        enterpriseId: this.enterpriseId,
        djxhid: this.djxhid,
        userId: this.userId,
      };
      //校验申报密码
      defaultfRegsterDjhm(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "校验成功");
        } else {
          this.$store.commit("MyMessage", "设置失败");
        }
      });
    },
    checkShenBaoPassword() {
      let param = {
        enterpriseId: this.enterpriseId,
        djxhid: this.djxhid,
        shenbaoPassword: this.shenbaoPassword,
        userId: this.userId,
      };
      //校验申报密码
      checkShenBaoPassword(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.$store.commit("MyMessage", "校验成功");
        } else {
          this.$store.commit("MyMessage", "校验失败，请输入正确的个税密码");
        }
      });
    },
    enterpriseRegister() {
      let param = {
        enterpriseId: this.enterpriseId,
        // djxhid: "10117540129973066918", //瑞琪
        djxhid: this.EnterpriseInfoFor.creditCode, //秦燕
        // djxhid: "10117110129972856538",
        shenbaoPassword: "Aa123456",
        userId: this.userId,
      };
      //企业登记-税友
      enterpriseRegister(param).then((res) => {
        console.log(res);
      });
    },
    queryRegsterDetail() {
      let param = {
        enterpriseId: this.enterpriseId,
        userId: this.userId,
      };
      //查询企业登记信息
      queryRegsterDetail(param).then((res) => {
        console.log(res);

        this.shenbaoPassword = res.data.register.shenbaoPassword;
        if (res.data.register.detailList.length > 0) {
          // this.$store.commit("MyMessage", "查询成功");
          // res.data.forEach((element) => {
          //   this.checkShenBaoPassword(element);
          // });
          this.detailList = res.data.register.detailList;
          if (res.data.register.detailList.length === 1) {
            this.djxhid = res.data.register.detailList[0].djxhid;
          } else {
            res.data.register.detailList.forEach((e) => {
              if (e.enterpriseId === this.enterpriseId) {
                this.djxhid = e.djxhid;
              }
            });
          }
        } else {
          // this.$store.commit("MyMessage", "暂无数据");
        }
      });
    },
    perfectEnterpriseInfo() {
      let EnterpriseInfoFor = this.$config.cloneObj(this.EnterpriseInfoFor);
      EnterpriseInfoFor.enterpriseAddress =
        EnterpriseInfoFor.enterpriseAddress.replace(/^\s+|\s+$/g, "");

      if (!EnterpriseInfoFor.company) {
        this.$store.commit("MyMessage", "请输入公司名称");
        return;
      }
      if (!EnterpriseInfoFor.creditCode) {
        this.$store.commit("MyMessage", "请输入统一社会信用代码");
        return;
      }
      if (!EnterpriseInfoFor.enterpriseProvince) {
        this.$store.commit("MyMessage", "请选择公司地址");
        return;
      }

      if (!EnterpriseInfoFor.enterpriseAddress) {
        this.$store.commit("MyMessage", "请输入公司详细地址");
        return;
      }
      if (!EnterpriseInfoFor.declareType) {
        EnterpriseInfoFor.declareType = 2;
      }
      if (
        EnterpriseInfoFor.bankList.length === 1 &&
        !EnterpriseInfoFor.bankList[0].bankCardNum
      ) {
        EnterpriseInfoFor.bankList = [];
      }
      // let enterpriseInfo = this.$config.cloneObj(EnterpriseInfoFor);
      EnterpriseInfoFor.bankList.forEach((e) => {
        e.bankCardNum = e.bankCardNum.replace(/\s+/g, "");
      });
      let param = {
        enterpriseInfo: EnterpriseInfoFor,
        userId: this.userId,
      };
      perfectEnterpriseInfo(param).then((res) => {
        if (res.code === 0) {
          this.queryEnterpriseInfoForUser();
          this.$store.commit("MyMessage", "保存成功");
          // location.reload();
        } else {
          this.$store.commit("MyMessage", res.msg);
        }
      });
    },
    bankAdd() {
      let flag = true;
      this.EnterpriseInfoFor.bankList.forEach((e, i) => {
        if (!e.bankName) {
          this.$store.commit("MyMessage", "请输入开户行名称");
          flag = false;
          return false;
        }
        if (!e.bankCardNum) {
          this.$store.commit("MyMessage", "请输入开户行卡号");
          flag = false;
          return false;
        }
      });
      if (!flag) {
        return false;
      }

      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
        baseAccount: 2,
        bankName: "",
        bankCardNum: "",
      };
      this.EnterpriseInfoFor.bankList.push(param);
    },
    bankDel(item, index) {
      if (item.bankcardId) {
        //删除银行
        this.$confirm("此操作不可逆，是否进行删除", "提示", {
          confirmButtonText: "删除",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            let param = {
              userId: this.userId,
              enterpriseId: this.enterpriseId,
              bankId: item.bankcardId,
            };
            deleteEnterpriseBank(param).then((res) => {
              if (res.code === 0) {
                this.EnterpriseInfoFor.bankList.splice(index, 1);
                if (this.EnterpriseInfoFor.bankList.length === 0) {
                  this.EnterpriseInfoFor.bankList.push({
                    userId: this.userId,
                    enterpriseId: this.enterpriseId,
                    baseAccount: 1,
                    bankName: "",
                    bankCardNum: "",
                  });
                }
              }
            });
          })
          .catch(() => {});
      } else {
        this.EnterpriseInfoFor.bankList.splice(index, 1);
      }
    },
    companyChange() {
      this.EnterpriseInfoFor.company = this.EnterpriseInfoFor.company.replace(
        /\s+/g,
        ""
      );
      this.$forceUpdate();
    },
    queryEnterpriseInfoForUser() {
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: this.enterpriseId,
      };
      queryEnterpriseInfoForUser(param).then((res) => {
        console.log("res", res);
        if (res.code === 0) {
          this.EnterpriseInfoFor = res.data;
          let fristIndex = null;
          let fristList = null;
          this.updataCity = [];
          this.updataCity.push(this.EnterpriseInfoFor.enterpriseProvinceCode);
          this.updataCity.push(this.EnterpriseInfoFor.enterpriseCityCode);
          this.updataCity.push(this.EnterpriseInfoFor.enterpriseAreaCode);
          if (this.EnterpriseInfoFor.bankList.length === 0) {
            this.EnterpriseInfoFor.bankList = [
              {
                userId: this.userId,
                enterpriseId: this.enterpriseId,
                baseAccount: 1,
                bankName: "",
                bankCardNum: "",
              },
            ];
          } else {
            this.EnterpriseInfoFor.bankList.forEach((element) => {
              element.bankCardNum = element.bankCardNum
                .replace(/\D+/g, "")
                .replace(/(\d{4})/g, "$1 ")
                .replace(/ $/, "");
            });
          }
        }
      });
    },
    addressChange(arr) {
      this.options.forEach((e1, k1) => {
        if (e1.value === arr[0]) {
          this.EnterpriseInfoFor.enterpriseProvinceCode = e1.value;
          this.EnterpriseInfoFor.enterpriseProvince = e1.label;
          e1.children.forEach((e2, k2) => {
            if (e2.value === arr[1]) {
              this.EnterpriseInfoFor.enterpriseCityCode = e2.value;
              this.EnterpriseInfoFor.enterpriseCity = e2.label;
              e2.children.forEach((e3, k3) => {
                if (e3.value === arr[2]) {
                  this.EnterpriseInfoFor.enterpriseAreaCode = e3.value;
                  this.EnterpriseInfoFor.enterpriseArea = e3.label;
                }
              });
            }
          });
        }
      });
      console.log(this.EnterpriseInfoFor);
    },
  },
};
</script>

<style lang="less" scoped>
.company-width {
  min-width: 480px;
  max-width: 480px;
  margin: 0px auto;
  width: calc(100% - 100px);
  padding: 0px 50px;
}
.company {
  height: 100%;
  overflow-y: auto;
  .company-body {
    min-height: calc(100% - 105px);
    margin-top: 20px;
  }
}
.accountTable {
  margin-bottom: 15px;
  tbody {
    tr {
      &:hover {
        background: #fff;
      }
    }
  }
  td {
    text-align: left;
    padding: 0 10px;
    input {
      background: #fff;
      height: 100%;
      width: 100%;
    }
    &:first-child {
      width: 125px;
      background: #f8f9fa;
    }
    &.td-padding {
      padding: 0;
      padding-left: 20px;
      line-height: 30px;
    }
  }
}
.inner-add-icon {
  color: #4c8afc;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  position: absolute;
  right: 19px;
  top: -3px;
}

.inner-del-icon {
  position: absolute;
  right: 19px;
  top: 12px;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
}
.bank-add-box {
  position: relative;
  height: 20px;
}
.plainStyle {
  margin-top: 20px;
  width: 100%;
  border: 1px solid #4c8afc;
  color: #4c8afc;
  font-weight: bold;
  &:hover {
    border: 1px solid #4c8afc;
    color: #4c8afc;
  }
}
td {
  position: relative;
}
.td-ipt {
  width: 90% !important;
  height: 100% !important;
}
.td-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 54px;
  height: 54px;
  line-height: 54px;
  cursor: pointer;
  text-align: center;
}
.icon-vivw {
  font-size: 20px;
}
.company-account {
  width: 1328px;
  margin: 0 auto 20px;
}
.company-account-title {
  color: #999;
  padding: 20px 0 10px;
}
.company-account-l {
  width: calc((100% - 30px) / 2);
  margin-left: 30px;
  float: left;
  &:first-child {
    margin-left: 0;
  }
}
.password-main {
  width: 420px;
  height: 300px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -210px;
  margin-top: -200px;
}
.baosong-main {
  width: 960px;
  min-height: 400px;
  background: #ffffff;
  opacity: 1;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -495px;
  margin-top: -300px;
  padding: 50px 15px 20px;
  .baosong-heigth {
    height: 100%;
  }
  .baosongTable {
    td {
      text-align: center;
      background: none !important;
      padding: 0;
    }
    tbody {
      tr {
        &:first-child {
          td {
            border-top: none;
          }
        }
      }
    }
  }
}
.table-overflow {
  width: 100%;
  height: calc(100% - 40px);
  overflow-y: auto;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  max-height: 400px;
  &::-webkit-scrollbar {
    width: 0;
  }
  .accountTable {
    border-top: none;
    border-bottom: none;
    margin-bottom: 0;
    tr {
      &:last-child {
        td {
          border-bottom: none;
        }
      }
    }
  }
}
.password-content {
  width: calc(100% - 80px);
  margin: 10px 40px 0;
}
.password-content-box {
  font-size: 14px;
  .password-box-l {
    width: 60px;
    float: left;
    height: 54px;
    line-height: 54px;
  }
  .password-box-r {
    width: calc(100% - 80px);
    margin-left: 20px;
    display: inline-block;
    height: 54px;
    line-height: 54px;
    .paddword-text {
      padding-left: 15px;
    }
    .el-select {
      width: calc(95% + 4px);
    }
    input {
      width: 90%;
      height: 40px;
      border: 1px solid #dbdbdb;
      margin: 6px 0;
      border-radius: 4px;
      padding-left: 15px;
      &::placeholder {
        color: #999;
      }
    }
  }
}
.earmarked-footer {
  width: 100%;
  margin-top: 20px;
  .earmarked-btn {
    width: calc(100% - 91px);
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border: 1px solid #1a73e8;
    opacity: 1;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 80px;
    cursor: pointer;
    text-align: center;
  }
}
.baosong-footer {
  width: 100%;
  margin-top: 40px;
  text-align: right;
  .earmarked-btn {
    width: 100px;
    height: 34px;
    line-height: 34px;
    background: #4c8afc;
    border: 1px solid #1a73e8;
    opacity: 1;
    border-radius: 3px;
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    margin-left: 80px;
    cursor: pointer;
    text-align: center;
  }
}
.bs-width1 {
  width: 90px;
  margin: 0 auto;
}
.bs-width2 {
  width: 100px;
  margin: 0 auto;
}
.bs-width3 {
  width: 60px;
  margin: 0 auto;
}
.bs-width4 {
  width: 80px;
  margin: 0 auto;
}
.bs-width5 {
  width: 80px;
  margin: 0 auto;
}
.bs-width6 {
  width: 120px;
  margin: 0 auto;
}
.bs-width7 {
  width: 140px;
  margin: 0 auto;
}
.bs-width8 {
  width: 140px;
  margin: 0 auto;
}
.bs-width9 {
  width: 140px;
  margin: 0 auto;
}
.moren-width2 {
  width: 512px;
  text-align: left;
  padding-left: 24px;
  cursor: pointer;
}
.moren-icon {
  display: inline-block;
  padding: 2px 5px;
  background: #ffaa45;
  color: #fff;
  border-radius: 5px;
  font-size: 12px;
  margin-left: 15px;
}
</style>
<style lang="less">
.enter-td {
  position: relative;
  .el-cascader {
    .el-input__icon {
      &:before {
        color: #262626;
      }
    }
  }
  .el-select,
  .el-cascader {
    width: 100%;
    .el-input {
      .el-input__inner {
        background: none !important;
        border: none;
        text-align: left;
        font-size: 14px;
        font-weight: 400;
        color: #262626;
        padding-left: 0;
        background: none;
      }
    }
    .el-icon-arrow-up:before {
      // content: "\e78f";
    }
    .el-select__caret {
      color: #262626;
    }
  }
  input {
    font: 400 14px Techna;
    color: #262626;
    &::placeholder {
      color: #bbbbbb;
    }
  }
  .el-input__inner {
    font: 400 14px Techna;
  }
  .el-textarea {
    width: 90%;
    float: left;
    textarea {
      padding: 0;
      margin: 0;
      resize: unset;
      border: none;
      padding: 10px 0;
      font: 400 14px Techna;
      background: none;
      color: #262626;
      &::placeholder {
        color: #bbbbbb;
      }
    }
  }
}
.enterpriseList {
  .el-table__body {
    tr {
      &:hover {
        .scope-div {
          color: #4c8afc;
        }
      }
    }
  }
}
.password-box-r {
  .el-select {
  }
}
</style>
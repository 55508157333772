<template>
  <div class="company">
    <div class="company-body">
      <div class="company-body-content">
        <div class="company-account">
          <p class="company-title">企业列表</p>
          <div class="enterpriseList" v-if="tableShow">
            <el-table
              :data="enterpriseList"
              :header-cell-style="{
                background: '#F4F4F4',
              }"
              :max-height="tableHeight"
              style="width: 100%"
            >
              <el-table-column
                prop="nickName"
                label="财税顾问"
                align="center"
                width="140"
              >
                <template>
                  <span class="scope-span">{{ mobile }}</span>
                </template>
              </el-table-column>
              <el-table-column prop="nickName" label="公司名称" align="left">
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    @click="
                      getEmployeeInfoUserId(scope, 'enterpriseInformation')
                    "
                  >
                    <span class="scope-span1">{{ scope.row.company }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="性质"
                align="center"
                width="140"
              >
                <template slot-scope="scope">
                  <span class="scope-span" v-if="scope.row.companyNature === 1">
                    小规模
                  </span>
                  <span class="scope-span" v-if="scope.row.companyNature === 2">
                    一般纳税人
                  </span>
                </template>
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="财务"
                align="center"
                width="140"
              >
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    @click="getEmployeeInfoUserId(scope, 'balance')"
                  >
                    <span class="scope-span1">查看</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="花名册"
                align="center"
                width="140"
              >
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    @click="getEmployeeInfoUserId(scope, 'roster')"
                  >
                    <span class="scope-span1">查看</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="工资"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    @click="getEmployeeInfoUserId(scope, 'payrollModule')"
                  >
                    <span class="scope-span1">查看</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="nickName"
                label="银行对账单"
                align="center"
                width="100"
              >
                <template slot-scope="scope">
                  <div
                    class="scope-div"
                    @click="getEmployeeInfoUserId(scope, 'bankFlow')"
                  >
                    <span class="scope-span1">查看</span>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <TheFooter></TheFooter>
  </div>
</template>
<script>
import { adminGetEnterpriseList, getEmployeeInfoUserId } from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      pcTkn: sessionStorage.getItem("pc-tkn"),
      mobile: sessionStorage.getItem("mobile"),
      tableHeight: "10",
      tableData: [
        {
          nickName: "13656568989",
        },
      ],
      tableShow: false,
      enterpriseList: [],
    };
  },
  components: {
    TheFooter,
  },
  mounted() {
    this.tableHeight = document.body.clientHeight - 200;
    this.adminGetEnterpriseList();
    this.tableShow = true;
  },
  methods: {
    getEmployeeInfoUserId(scope, link) {
      let row = scope.row;
      var that = this;
      let param = {
        userId: this.userId,
        enterpriseId: row.id,
      };
      getEmployeeInfoUserId(param).then((res) => {
        // console.log(res);
        if (res.code === 0) {
          let jurisdiction = null;
          jurisdiction = this.$jurisdiction.authorization(
            res.data.userAuthorizationList
          );
          if (!jurisdiction) {
            this.$store.commit("MyMessage", "暂无权限");
          } else {
            sessionStorage.setItem("jurisdiction", jurisdiction);
            this.showClick(scope, link);
          }
        }
      });
    },
    adminGetEnterpriseList() {
      var that = this;
      let param = {
        userId: that.userId,
      };
      adminGetEnterpriseList(param).then((res) => {
        // console.log(res.data);
        if (res.code === 0) {
          that.enterpriseList = [];
          let num = 0;
          res.data.forEach((element) => {
            if (element.isTiyan === 0) {
              num++;
              if (element.id === that.enterpriseId) {
                that.offsetNum = num;
              }
              that.enterpriseList.push(element);
            }
          });
          that.enterpriseListBase = that.$config.cloneObj(that.enterpriseList);
        }
      });
    },
    showClick(scope, link) {
      let row = scope.row;
      if (link) {
        // return;
        console.log(row.id);
        sessionStorage.setItem("enterpriseId", row.id);
        this.$setCookie("enterpriseId", row.id);
        var { href } = this.$router.resolve({
          path: "/transfer",
          query: {
            pcTkn: this.pcTkn,
            EnterpriseType: row.accountingstandard,
            // visit: "visit",
            adminId: this.userId,
            id: row.id,
            url: link,
          },
        });
        window.open(href);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.company {
  height: 100%;
  overflow-y: auto;
  .company-body {
    min-height: calc(100% - 105px);
    margin-top: 20px;
  }
}
.enterpriseList {
  border-right: 1px solid #dbdbdb;
  width: 1024px;
  margin: 0 auto;
  min-width: 1124px;
}
.scope-span {
  font-size: 14px;
  cursor: pointer;
}
.scope-span1 {
  font-size: 14px;
  cursor: pointer;
  // color: #4c8afc;
}

.scope-div {
  width: 100%;
  height: 100%;
}
.company-title {
  text-align: center;
  font-size: 24px;
  padding: 20px 0;
}
</style>
<style lang="less">
.enterpriseList {
  .el-table {
    th {
      border: 1px solid #dbdbdb;
    }
    td {
      border: 1px solid #dbdbdb;
      border-top: none;
      cursor: pointer;
    }
  }
}
</style>
<template>
  <div class="company">
    <div class="company-body">
      <div class="company-body-content">
        <div class="payserver">
          <!-- <div class="payserver-l">
            <p class="payserver-title">操作</p>
            <div class="payserver-btn" @click="refunds">退 款</div>
          </div> -->
          <div class="payserver-r">
            <p class="payserver-title">交易记录</p>
            <div class="payserver-list">
              <table>
                <thead>
                  <tr>
                    <th class="w1">交易时间</th>
                    <th class="w2">交易金额</th>
                    <th class="w3">操作</th>
                  </tr>
                </thead>
              </table>
              <div class="payserver-body">
                <table>
                  <tbody>
                    <tr v-for="(e, i) in list" :key="i">
                      <td class="w1">充值时间</td>
                      <td class="w2">{{ e.transAmount }}</td>
                      <td class="w3">
                        <div
                          class="w3-div"
                          @click="refunds(e)"
                          v-if="e.transType === 1"
                        >
                          退款
                        </div>
                        <div v-else>---</div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TheFooter></TheFooter>
  </div>
</template>
<script>
import { refunds, queryOrders } from "@/http/api";
import TheFooter from "@/components/TheFooter.vue";
export default {
  data() {
    return {
      enterpriseId: sessionStorage.getItem("enterpriseId"),
      userId: sessionStorage.getItem("userId"),
      list: [],
    };
  },
  components: {
    TheFooter,
  },
  mounted() {
    this.queryOrders();
  },
  methods: {
    queryOrders() {
      let param = {
        // enterpriseId: this.enterpriseId,
        enterpriseId: "1111",
        userId: this.userId,
        pageNum: 1,
        pageSize: 100,
      };
      queryOrders(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
          this.list = res.data.list;
        }
      });
    },
    refunds(e) {
      let param = {
        enterpriseId: "1111",
        transOrderNo: e.transOrderNo,
        userId: this.userId,
      };
      refunds(param).then((res) => {
        console.log(res);
        if (res.code === 0) {
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.company {
  height: 100%;
}
.company .company-body {
  min-height: calc(100% - 105px);
  margin-top: 20px;
}
.payserver {
  width: 1328px;
  margin: 0 auto;
}
.payserver-l {
  width: 30%;
  float: left;
}
.payserver-r {
  width: 70%;
  float: left;
}
.payserver-ipt {
  border: 1px solid #4c8afc;
  width: 120px;
  padding: 5px 10px;
  margin-bottom: 15px;
  border-radius: 3px;
  &&::placeholder {
    color: #bbb;
  }
}
.payserver-btn {
  padding: 5px 10px;
  border: 1px solid #4c8afc;
  width: 120px;
  text-align: center;
  color: #4c8afc;
  border-radius: 3px;
  font-size: 14px;
  cursor: pointer;
}
.payserver-title {
  color: #bbb;
  font-size: 16px;
  padding-bottom: 10px;
}
.payserver-list {
  text-align: center;
  th,
  td {
    border: 1px solid #bbb;
    height: 30px;
  }
  tbody {
    tr {
      &:first-child {
        td {
          border-top: none;
        }
      }
    }
  }
  .w1 {
    width: 120px;
  }
  .w2 {
    width: 200px;
  }
  .w3 {
    width: 50px;
    .w3-div {
      color: #4c8afc;
      cursor: pointer;
    }
  }
}
.payserver-body {
  max-height: 500px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 0;
  }
}
</style>